
import { ElMessageBox, ElNotification } from 'element-plus'
import { defineComponent, onMounted, ref } from 'vue'
import { adjustShopRate, getShopRate } from '@/api/shop/shop.api'
import { useRoute } from 'vue-router'

export enum RATE_PROMOTION_NAME {
	FLASH_PROMOTION = 'FLASH_PROMOTION',
	THAILAND_POST_PROMOTION = 'THAILAND_POST_PROMOTION',
}

export default defineComponent({
	name: 'ShopRate',
	setup() {
		//
		const rateDetail = ref(null)
		const isLoading = ref(false)
		const route = useRoute()

		const adjustRate = async (rateName = 'FIRST_PROMOTION') => {
			//
			const isConfirm = await ElMessageBox.confirm(
				'ยืนยันการปรับเรทค่าส่งร้านค้า ?',
				{
					confirmButtonText: 'ยืนยัน',
					cancelButtonText: 'ยกเลิก',
					type: 'warning',
				}
			)

			if (!isConfirm) return

			const { data: response } = await adjustShopRate({ shopId: route.params.shopId as string, rateName })

			if (response.updateStatus === 'SUCCESSFUL') {
				ElNotification({
					title: 'สำเร็จ',
					message: 'อัพเดท ค่าขนส่งสำเร็จ',
					type: 'success'
				})

				fetchRate()
			}
		}

		const fetchRate = async () => {
			isLoading.value = true
			try {
				const { data: response } = await getShopRate(route.params.shopId as string)

				rateDetail.value = response.couriers 
			} catch (error) {
				console.error(error)
			} finally {
				isLoading.value = false
			}
		}

		onMounted(() => {
			fetchRate()
		})

		return {
			adjustRate,
			rateDetail,
			isLoading,
			RATE_PROMOTION_NAME,
		}
	}
})
